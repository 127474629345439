import { ArrowIcon } from 'components/ui/icons';
import React from 'react';
import { SliderValue } from 'types';
import './Slider.css';

/**
 * Calculates the new slider value based on touch position.
 *
 * @param touchPosition - The X-coordinate of the touch event.
 * @param value - The current object containing the currentSlice and maxSlice.
 * @returns The new slider value.
 */
function calculateNewSliderValue(touchPosition: number, value: SliderValue): number {
  const sliderElement = document.getElementById('volume-slider');
  const { left, width } = sliderElement.getBoundingClientRect();
  const positionRelativeToSlider = touchPosition - left;

  const touchIndex = positionRelativeToSlider / width;
  return Math.round(touchIndex * (value.maxSlice - 1));
}

interface UISliderProps {
  value: SliderValue;
  onSliderChange: React.Dispatch<React.SetStateAction<number>>;
  useArrows?: boolean;
}

const UISlider = ({
  value,
  onSliderChange,
  useArrows = false,
}: UISliderProps) => {
  const handleTouchEvent = (e: React.TouchEvent) => {
    const newValue = calculateNewSliderValue(e.touches[0].clientX, value);
    onSliderChange(newValue);
  };
  const isScreenWiderThan950 = window.screen.availWidth > 950;
  const handleSliderChange = (newValue: number) => {
    /* since the touch event is handled above this check prevents double calling
    when on mobile */
    if (isScreenWiderThan950) {
      onSliderChange(newValue);
    }
  };

  return (
    <div className='slider-container'>
      {useArrows && (
        <div className='arrow-icon' onClick={() => onSliderChange(value.currentSlice - 1)}>
          <ArrowIcon />
        </div>
      )}
      <div
        className='input-container'
        onTouchStart={handleTouchEvent}
        onTouchMove={handleTouchEvent}
      >
        <input
          type='range'
          id='volume-slider'
          value={value.currentSlice}
          min={0}
          max={value.maxSlice - 1}
          onChange={(e) => handleSliderChange(Number(e.target.value))}
        />
      </div>
      {useArrows && (
        <div
          className='rotate-180 arrow-icon'
          onClick={() => onSliderChange(value.currentSlice + 1)}
        >
          <ArrowIcon />
        </div>
      )}
    </div>
  );
};

export default UISlider;
