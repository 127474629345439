export function formatIsoToMonthDayHourMinute12h(isoDateString: string): string {
  const dateObj = new Date(isoDateString);
  return dateObj
    .toLocaleString('en-GB', {
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    .replace(',', '').toUpperCase();
}

export function formatIsoToHHMM(isoDateString: string): string {
  const dateObj = new Date(isoDateString);
  return dateObj
    .toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true })
    .replace(/(am|pm)/g, (match) => match.toUpperCase());
}

export function formatIsoToDayMonthYear(isoDateString: string): string {
  const dateObj = new Date(isoDateString);
  return dateObj.toLocaleString('en-GB', { month: 'long', day: 'numeric', year: 'numeric' });
}
