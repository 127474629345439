import { Types } from '@cornerstonejs/core';
import SliderMenu from 'components/ui/menus/slider/SliderMenu';
import { useMipsContext } from 'context/MipsContext';
import React, { useState } from 'react';
import getCurrentSliceSpacing from 'utils/cornerstone/volumeUtils';
import { getViewport } from 'utils/utilities';
import { ANATOMICAL_AXES } from '../../constants';

type MipsMenuProps = {
  setTopBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

function MipsMenu({ setTopBar }: MipsMenuProps) {
  const { setMipsValue } = useMipsContext();
  const viewport = getViewport() as Types.IVolumeViewport;

  // Get current axis and spacing information
  const axisIndex = ANATOMICAL_AXES[viewport.defaultOptions.orientation.toUpperCase()];
  const sliceSpacing = getCurrentSliceSpacing();
  const totalSlices = viewport.getImageData().dimensions[axisIndex];

  // Calculate maximum and initial values
  const maxSlices = Math.min(Math.floor(totalSlices / 3), 50);
  const initialSlices = Math.round(viewport.getSlabThickness() / sliceSpacing);

  // Manage slider state
  const [sliderValue, setSliderValue] = useState({
    currentSlice: initialSlices,
    maxSlice: maxSlices,
  });

  const handleSliderChange = (newSliceCount: number) => {
    const boundedSliceCount = Math.max(Math.min(newSliceCount, maxSlices), 0);
    const newThickness = boundedSliceCount * sliceSpacing;

    setSliderValue({
      currentSlice: boundedSliceCount,
      maxSlice: maxSlices,
    });
    const displayValue = newThickness + sliceSpacing;

    setMipsValue(newThickness, displayValue);
  };

  return (
    <SliderMenu
      menuContent={{
        displayValue: 'MIPs',
        valueExtension: sliderValue.currentSlice > 1 ? 'slices' : 'slice',
      }}
      sliderValue={sliderValue}
      handleSliderChange={handleSliderChange}
      setTopBar={setTopBar}
    />
  );
}

export default MipsMenu;
