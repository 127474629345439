import DefaultCanvasMenu from 'components/menuInputs/DefaultMenuCanvas';
import { CrossIcon } from 'components/ui/icons';
import UISlider from 'components/ui/slider/Slider';
import React from 'react';
import { SliderValue } from 'types';
import './SliderMenu.css';

type menuDisplayOptions = {
  displayValue: string;
  valueExtension?: string;
};

type SliderMenuProps = {
  menuContent: menuDisplayOptions;
  sliderValue: SliderValue;
  handleSliderChange: React.Dispatch<React.SetStateAction<number>>;
  setTopBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};
function SliderMenu({
  menuContent,
  sliderValue,
  handleSliderChange,
  setTopBar,
}: SliderMenuProps) {
  return (
    <div className='slider-menu-container'>
      <div className='menu-text'>{menuContent.displayValue}</div>
      <div className='slider-container'>
        <div className='slider-wrapper'>
          <UISlider value={sliderValue} onSliderChange={handleSliderChange} />
        </div>
        <div className='slider-value'>
          {sliderValue.currentSlice + 1} {menuContent.valueExtension ?? ''}
        </div>
      </div>
      <div
        className='cross-icon'
        onClick={() => setTopBar(<DefaultCanvasMenu setTopBar={setTopBar} />)}
      >
        <CrossIcon />
      </div>
    </div>
  );
}

export default SliderMenu;
