import FunctionalText from 'components/ui/text/FunctionalText';
import useView from 'hooks/useView';
import React from 'react';
import { VIEW_STATE } from '../../constants';
import './TopNav.css';

const TopNav = () => {
  // const [activeProduct, setActiveProduct] = useState('FDA');

  // const handleProductClick = (product: string) => {
  //   setActiveProduct(product);
  // };

  const [, updateView] = useView();
  // const products = ['FDA', 'RES'];

  /* The help button is currently pending implementation along with
  the commented code in this file */
  return (
    <div className='top-nav-container'>
      {/* <FunctionalText text='Help' className='blue-text' onClick={() => {}} /> */}
      {/* <div>
        <div className='top-nav-button-container'>
          {products.map((product) => (
            <span
              key={product}
              className={`top-nav-button ${activeProduct === product ? 'activeButton' : ''}`}
              onClick={() => handleProductClick(product)}
            >
              {product}
            </span>
          ))}
        </div>
      </div> */}
      <FunctionalText
        text='Timeline'
        className='blue-text'
        onClick={() => {
          updateView(VIEW_STATE.TIMELINE);
        }}
      />
    </div>
  );
};

export default TopNav;
