import './styles/style_normalize.css';
import './styles/styles.css';
import Support from 'pages/support/Support';
import { ThemeProvider } from '@mui/material';
import handleConfigureMode from 'utils/teams';
import MainView from 'pages/MainView';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import theme from 'styles/theme';
import { MipsProvider } from 'context/MipsContext';
import { WindowingProvider } from 'context/Windowing';
import { LesionProvider } from 'context/Lesions';
import useCaseData from 'hooks/useCaseData';
import GeneralError from 'pages/errorPages/General';
import Skeleton from 'components/ui/skeleton/Skeleton';
import AuthenticationErrorPage from 'pages/errorPages/Authentication';
import InvalidResultErrorPage from 'pages/errorPages/InvalidResults';
import ConfigPage from 'pages/Config';
import { initialize } from '@microsoft/teams-js';

export type AppState = 'LOADING' | 'ERROR' | 'SUCCESS';

const App = () => {
  if (window.location.href.includes('?help')) {
    return <Support />;
  }
  initialize();
  const url = window.location.href;
  if (url.includes('configure')) {
    handleConfigureMode();
    return <ConfigPage />;
  }

  const [appState, setAppState] = useState<AppState>('LOADING');
  const { data, error } = useCaseData();

  useEffect(() => {
    if (data) {
      setAppState('SUCCESS');
    } else if (error) {
      setAppState('ERROR');
    }
  }, [data, error]);

  const renderErrorComponent = () => {
    switch (error.status) {
      case 401:
        return <AuthenticationErrorPage />;
      case 400:
        return <InvalidResultErrorPage />;
      default:
        return <GeneralError />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <WindowingProvider>
        <LesionProvider>
          <MipsProvider>
            {appState === 'LOADING' && <Skeleton />}
            {appState === 'SUCCESS' && <MainView currentCase={data} />}
            {appState === 'ERROR' && renderErrorComponent()}
          </MipsProvider>
        </LesionProvider>
      </WindowingProvider>
    </ThemeProvider>
  );
};

const rootElement = document.getElementById('root');

if (rootElement) {
  createRoot(rootElement).render(<App />);
}
