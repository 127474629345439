import ViewerMenu from 'components/ViewerMenu/ViewerMenu';
import LesionMenu from 'components/menuInputs/LesionMenu';
import Canvas from 'components/ui/canvas/Canvas';
import UISlider from 'components/ui/slider/Slider';
import useView from 'hooks/useView';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { SliderValue, VolumeIds } from 'types';
import { handleJumpToSlice, handleResize } from 'utils/utilities';
import { VIEW_STATE } from '../../constants';
import { StackIcon } from '../ui/icons';
import './Viewer.css';

const BottomMenu = ({ hasRendered, sliderValue }) => (
  <div className='bottom-menu'>
    <div className='bottom-nav font-weight-500'>
      <StackIcon />
      {hasRendered ? `${sliderValue.currentSlice + 1}/${sliderValue.maxSlice}` : ''}
    </div>
    <div className='bottom-nav'>Not for diagnostic use</div>
  </div>
);

export default function Viewer({
  initialVolumeIds,
  showLesionMenu,
}: {
  initialVolumeIds: VolumeIds;
  showLesionMenu: boolean;
}) {
  const [activeView] = useView();
  // TODO update to use native cornerstone event
  const handleDeviceOrientationChange = debounce(() => {
    handleResize(true);
  }, 50);

  useEffect(() => {
    window.addEventListener('resize', () => handleResize());
    window.addEventListener('orientationchange', handleDeviceOrientationChange);
    return () => {
      window.removeEventListener('resize', () => handleResize());
      window.removeEventListener('orientationchange', handleDeviceOrientationChange);
    };
  }, []);
  const [sliderValue, setSliderValue] = useState<SliderValue>({
    currentSlice: 0,
    maxSlice: 0,
  });
  const hasRendered = sliderValue.maxSlice > 0;

  const handleSliderChange = async (value: number) => {
    await handleJumpToSlice(value, setSliderValue);
  };

  // Flexbox with 2 items, column, space between for top/bottom canvas separation
  return (
    <div className={`nifti-viewer ${activeView === VIEW_STATE.TIMELINE ? 'none' : ''}`}>
      <Canvas initialVolumeIds={initialVolumeIds} setSliderValue={setSliderValue} />
      <div className='menu-spacing'>
        <div className='top-menu-container'>
          <ViewerMenu />
        </div>
        <div className={`bottom-menu-container ${showLesionMenu ? '' : 'add-margin-bottom'}`}>
          <BottomMenu hasRendered={hasRendered} sliderValue={sliderValue} />
          <UISlider value={sliderValue} onSliderChange={handleSliderChange} useArrows={true} />
          {showLesionMenu && <LesionMenu />}
        </div>
      </div>
    </div>
  );
}
