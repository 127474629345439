import React, { createContext, useContext, useState } from 'react';
import { updateSlabThickness } from 'utils/utilities';
import { MipsValue } from '../types';

interface MipsContextType {
  mipsValue: MipsValue;
  // eslint-disable-next-line
  setMipsValue(_newValue: MipsValue, _displayValue: MipsValue): void;
}

const MipsContext = createContext<MipsContextType | null>(null);

export function MipsProvider({ children }: { children: React.ReactNode }) {
  const [mipsValue, setMipsValueState] = useState<MipsValue>(0);

  const setMipsValue = (newValue: MipsValue, displayValue: MipsValue) => {
    setMipsValueState(displayValue);
    updateSlabThickness(newValue);
  };

  return (
    <MipsContext.Provider value={{ mipsValue, setMipsValue }}>
      {children}
    </MipsContext.Provider>
  );
}

export function useMipsContext() {
  const context = useContext(MipsContext);
  if (!context) {
    throw new Error('useMipsContext must be used within a MipsProvider');
  }
  return context;
}
