import { Box } from '@mui/material';
import React from 'react';
import './Infomodal.css';

const InfoModal = () => (
  <div className='info-modal'>
    <Box className='modal-arrow'></Box>
    <Box className='info-modal-content'>
      <div>
        <span className='first-word'>Windowing: </span>
        Swipe across the screen to adjust the windowing manually,
        or tap the tool to select from predefined settings.
      </div>
      <div>
        <span className='first-word'>Pan: </span>
        Use two fingers to pinch the screen, then drag to reposition the image.
      </div>
      <div>
        <span className='first-word'>Zoom: </span>
        Pinch your fingers together to zoom out or spread them apart to zoom in.
      </div>
      <div>
        <span className='first-word'>Change Orientation: </span>
        Tap the orientation tool to switch between different anatomical planes.
      </div>
    </Box>
  </div>
);

export default InfoModal;
