import ImageDropdown from 'components/imagedropdown/ImageDropdown';
import PatientInfo from 'components/patientinfo/PatientInfo';
import Results from 'components/results/Result';
import Timeline from 'pages/Timeline';
import AdvancedTopNav from 'components/topnav/advanced/AdvancedTopNav';
import TopNav from 'components/topnav/TopNav';
import Viewer from 'components/viewer/Viewer';
import React, { useState } from 'react';
import { CurrentCase } from 'types';
import { shouldDisplayLesionMenu } from 'utils/heatmaps/heatmaps';
import formatImageData from 'utils/mapping/formatImageData';
import { DEVICE_ORIENTATION, VIEW_STATE } from '../constants';
import useView from '../hooks/useView';
import './MainView.css';

export default function MainView({ currentCase }: { currentCase: CurrentCase }) {
  const [activeView] = useView();

  const { patient } = currentCase;
  const selectedProduct = currentCase.selected_product;
  const selectedImage = currentCase.selected_image;
  const niftiUrl = currentCase.nifti_urls;
  const studyDatetime = currentCase.study_datetime;
  const initialVolumeId = `nifti:${niftiUrl[parseInt(selectedImage, 10)].image}`;

  const isScreenWiderThanTall = window.innerWidth > window.innerHeight;
  const isScreenWiderThan950 = window.innerWidth > 950;
  const [showLesionMenu, setShowLesionMenu] = useState(
    shouldDisplayLesionMenu(
      currentCase.selected_product,
      currentCase.images[currentCase.selected_product][currentCase.selected_image].type,
    ),
  );
  const productResult = currentCase.product_results[currentCase.selected_product];
  const { images } = currentCase;
  const lesionVolumeIds = Object.values(
    currentCase.nifti_urls[currentCase.selected_image].lesions || {},
  )
    .flatMap((lesionGroup) => Object.values(lesionGroup))
    .filter(Boolean)
    .map((url) => `nifti:${url}`);

  const initialVolumeIds = {
    image: initialVolumeId,
    lesions: lesionVolumeIds,
  };
  const formattedImages = formatImageData(
    images,
    selectedProduct,
    niftiUrl,
    selectedImage,
    setShowLesionMenu,
  );

  const evaluateDeviceOrientation = () => (window.innerHeight > window.innerWidth
    ? DEVICE_ORIENTATION.PORTRAIT
    : DEVICE_ORIENTATION.LANDSCAPE);
  const [deviceOrientation, setDeviceOrientation] = React.useState<DEVICE_ORIENTATION>(
    evaluateDeviceOrientation(),
  );

  React.useEffect(() => {
    const handleResizeDimensions = () => {
      setDeviceOrientation(evaluateDeviceOrientation());
    };
    window.addEventListener('resize', handleResizeDimensions);

    return () => {
      window.removeEventListener('resize', handleResizeDimensions);
    };
  }, []);
  const isTimelineView = activeView === VIEW_STATE.TIMELINE;
  const isPortraitOrientation = deviceOrientation === DEVICE_ORIENTATION.PORTRAIT;

  const showViews = isPortraitOrientation
    || (isScreenWiderThan950 && isScreenWiderThanTall)
    || isTimelineView;

  const baseView = (
    <div className='normal-menus main-view-menus'>
      <TopNav />
      <PatientInfo patientData={patient} studyDate={studyDatetime} />
      <Results productResult={productResult} />
      <ImageDropdown formattedImages={formattedImages} />
    </div>
  );

  const advancedView = <AdvancedTopNav productResult={productResult} />;
  const timelineView = (
    <Timeline
      patientData={patient}
      studyDate={studyDatetime}
    />
  );

  const viewMap = {
    [VIEW_STATE.BASE]: baseView,
    [VIEW_STATE.ADVANCED]: advancedView,
    [VIEW_STATE.TIMELINE]: timelineView,
  };

  return (
    <div className='main-view'>
      {showViews && <div>{viewMap[activeView]}</div>}
      <Viewer initialVolumeIds={initialVolumeIds} showLesionMenu={showLesionMenu} />
    </div>
  );
}
