import React from 'react';
import { ProductResult } from 'types';
import './AdvancedTopNav.css';

type AdvancedTopNavProps = {
  productResult: ProductResult;
};
export default function AdvancedTopNav({ productResult }: AdvancedTopNavProps) {
  const resultSummary = productResult.summary;

  return (
    <div className='advanced-top-nav-container'>
      <span
        className={
          resultSummary.includes('Suspected')
            ? 'results-accordion-header-warning'
            : 'results-accordion-header'
        }
      >
        {resultSummary}
      </span>
    </div>
  );
}
